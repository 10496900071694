<template>
  <div>
    <div class="row page-title-header">
      <div class="col-12">
        <div class="page-header">
          <b-breadcrumb class="m-0">
            <b-breadcrumb-item
              :to="{
                name: 'DashboardHome',
              }"
            >
              <i class="fa fa-home"></i>
            </b-breadcrumb-item>
            <b-breadcrumb-item active>組織資料中心</b-breadcrumb-item>
            <b-breadcrumb-item active>模組管理</b-breadcrumb-item>
          </b-breadcrumb>
        </div>
      </div>
    </div>
    <b-card>
      <div class="row d-flex mb-4 mb-xl-2 justify-content-between">
        <h4 class="col-12 col-xl-6 mb-2 mb-xl-0 font-weight-bold">
          {{ `【${organizationName}】模組管理` }}
        </h4>
        <div
          class="
            col-12 col-xl-6
            d-flex
            flex-column flex-xl-row
            align-items-start align-items-xl-center
          "
        >
          <b-button
            v-if="checkPermission([consts.MODULE_CREATE])"
            class="flex-shrink-0 mb-2 mb-xl-0 mr-2"
            variant="primary"
            @click="handleCreate"
          >
            <i class="fa fa-plus"></i>新增模組
          </b-button>
          <b-form-select
            v-model="selectedMerchantID"
            :options="merchants"
            :disabled="isFetchMerchants"
            @change="handleChangeMerchant"
            class="mb-2 mb-xl-0 mr-2"
          ></b-form-select>
          <b-input-group>
            <b-form-input
              placeholder="搜尋模組名稱"
              v-model="search"
              @keyup.enter="handleSearch"
            ></b-form-input>
            <b-input-group-append>
              <b-button @click="handleSearch">
                <i class="fa fa-search"></i>
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </div>
      </div>

      <b-table
        striped
        hover
        responsive
        :items="modules"
        :fields="fields"
        :busy="isLoading"
      >
        <template #table-busy>
          <div class="text-center my-2">
            <b-spinner class="align-middle"></b-spinner>
          </div>
        </template>
        <template #cell(name)="data">
          {{ data.item.config && data.item.config.name }}
          <BIconQuestionCircle
            class="d-inline-block"
            :id="`popover-tips-${data.item.id}`"
            v-if="data.item.config && data.item.config.description"
          ></BIconQuestionCircle>
          <b-popover
            :target="`popover-tips-${data.item.id}`"
            triggers="hover focus click"
          >
            <div class="text-left">
              {{ data.item.config && data.item.config.description }}
            </div>
          </b-popover>
        </template>
        <template #cell(actions)="data">
          <b-button
            v-if="
              data.item.status == 'inactivated' &&
              checkPermission([consts.MODULE_LIST_VIEW])
            "
            variant="primary"
            @click="handleActivate(data.item.module_code)"
          >
            開通
          </b-button>
          <b-button
            v-if="
              data.item.status != 'inactivated' &&
              checkPermission([consts.MODULE_MODIFY])
            "
            class="ml-2"
            variant="inverse-info"
            :to="{
              name: 'ModuleConfig',
              params: {
                orgId: orgId,
                moduleId: data.item.id,
              },
            }"
          >
            <span class="mdi mdi-settings"></span>
            設定
          </b-button>
          <b-button
            v-if="
              data.item.status != 'inactivated' &&
              checkPermission([consts.MODULE_LIST_VIEW])
            "
            class="ml-2"
            variant="inverse-primary"
            :to="{
              name: 'ModuleShow',
              params: {
                orgId: orgId,
                moduleId: data.item.id,
              },
            }"
          >
            <span class="mdi mdi-eye"></span>
            查看
          </b-button>
          <b-button
            v-if="
              data.item.status != 'inactivated' &&
              checkPermission([consts.MODULE_MODIFY])
            "
            class="ml-2"
            variant="inverse-warning"
            :to="{
              name: 'ModuleEdit',
              params: {
                orgId: orgId,
                moduleId: data.item.id,
              },
            }"
          >
            <span class="mdi mdi-lead-pencil"></span>
            編輯
          </b-button>
          <b-button
            v-if="
              data.item.status != 'inactivated' &&
              checkPermission([consts.MODULE_DELETE])
            "
            class="ml-2"
            variant="inverse-danger"
            :disabled="data.item.is_enabled === 1"
            @click="handleDelete(data.item.id, data.item.module_code)"
          >
            <span class="mdi mdi-delete"></span>
            刪除
          </b-button>
        </template>
      </b-table>

      <div class="d-flex justify-content-center" style="margin-top: 80px">
        <b-pagination
          class="separated"
          v-model="currentPage"
          :total-rows="total"
          :per-page="perPage"
          aria-controls="moduleList"
          @change="(page) => fetchModules(page)"
        ></b-pagination>
      </div>
    </b-card>
  </div>
</template>

<script>
import moduleApi from "../../../apis/module";
import { BIconQuestionCircle } from "bootstrap-vue";
import merchantApi from "../../../apis/merchant";
import * as consts from "@/consts";
import PermissionChecker from "@/utils/PermissionChecker";

export default {
  components: {
    BIconQuestionCircle,
  },
  data() {
    return {
      consts,
      isFetchMerchants: false,
      selectedMerchantID: null,
      isLoading: false,
      search: "",
      modules: [],
      merchants: [{ value: null, text: "請選擇" }],
      fields: [
        {
          key: "merchant.name",
          label: "通路",
        },
        {
          key: "name",
          label: "模組名稱",
        },
        {
          key: "module_code",
          label: "代號",
        },
        {
          key: "status",
          label: "是否啟用",
          formatter: (value) => {
            switch (value) {
              case "inactivated":
                return "未開通";
              case "enabled":
                return "啟用";
              case "disabled":
                return "未啟用";
              case "enabled-but-not-in-config-mapping":
                return "啟用(無設定檔)";
              default:
                return "啟用(無設定檔)";
            }
          },
        },
        {
          key: "actions",
          label: "操作",
        },
      ],
      organizationName: "",
      perPage: 15,
      currentPage: 1,
      total: 0,
    };
  },
  computed: {
    orgId() {
      return this.$route.params.orgId;
    },
  },
  async mounted() {
    if (!this.checkPermission([consts.MODULE_LIST_VIEW])) {
      this.$swal
        .fire({
          type: "error",
          text: "你沒有權限訪問此頁面",
          confirmButtonColor: "#d33",
        })
        .then(() => {
          this.$router.push({ name: "OrganizationList" });
        });
    } else {
      await this.getMerchants();
      await this.fetchModules();
    }
  },
  methods: {
    async handleChangeMerchant() {
      await this.getMerchants();
      await this.fetchModules();
    },
    async fetchModules(page) {
      try {
        this.isLoading = true;
        const { data } = await moduleApi.getModules(this.orgId, {
          keyword: this.search,
          merchant_id: this.selectedMerchantID,
          page: page || this.currentPage,
          per_page: this.perPage || 15,
        });
        this.total = data.meta.total;
        this.perPage = data.meta.per_page;
        this.modules = data.data;
        this.organizationName = data.meta.organization_name;
      } catch (error) {
        console.log("");
      }
      this.isLoading = false;
    },
    handleSearch() {
      this.fetchModules();
    },
    async getMerchants() {
      this.isFetchMerchants = true;
      const { data } = await merchantApi.list();
      this.isFetchMerchants = false;

      this.merchants = [
        ...data.data.map((merchant) => {
          return {
            value: merchant.id,
            text: merchant.type_name
              ? `${merchant.name} (${merchant.type_name})`
              : merchant.name,
          };
        }),
      ];

      if (!this.selectedMerchantID && data.data[0]) {
        this.selectedMerchantID = data.data[0].id;
      }
    },
    async handleActivate(module_code) {
      if (!this.merchants.length) {
        this.$swal.fire({
          type: "error",
          text: "錯誤，請先建立通路",
          confirmButtonColor: "#d33",
        });
        return;
      }
      this.isLoading = true;
      await moduleApi.activateModule(this.orgId, {
        merchant_id: this.selectedMerchantID,
        module_code: module_code,
      });
      await this.getMerchants();
      await this.fetchModules();
      this.isLoading = false;
    },
    async handleDelete(moduleId, module_code) {
      this.$swal
        .fire({
          type: "warning",
          title: "你確定要刪除嗎？",
          html: `
            <div class="d-block">
              <div class="my-3">
                <div>模組代號：${module_code}</div>
              </div>
              <div>刪除後，相關資料將無法回復，<br/>請您確認！</div>
            </div>`,
          confirmButtonText: "確定刪除",
          cancelButtonText: "不刪除",
          showCancelButton: true,
          confirmButtonColor: "#d33",
          cancelButtonColor: "#3085d6",
          reverseButtons: true,
        })
        .then(async (result) => {
          if (result.value) {
            try {
              this.isLoading = true;
              await moduleApi.deleteModule(this.orgId, moduleId);
              await this.getMerchants();
              await this.fetchModules(this.orgId);
            } catch (error) {
              console.log("");
            }
            this.isLoading = false;
          }
        });
    },
    handleCreate() {
      this.$router.push({
        name: "ModuleCreate",
      });
    },
    checkPermission(permissions) {
      const checker = new PermissionChecker();
      return checker.check(permissions);
    },
  },
};
</script>
